body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
.container {
  height: 100px;
  width: 200px;
  margin: 160px auto 0;
  position: relative;
}
.circle {
  background-color: #fff;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #36C5F0; /* Slack blue color */
  border-radius: 100px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  animation-name: circle;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
}
.fa {
  font-size: 42px;
  color: #36C5F0; /* Slack blue color */
  position: absolute;
  top: -0px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: arrow;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
}

@keyframes arrow {
  0% {
    top: 35px;
  }
  75% {
    top: 0px;
  }
  100% {
    top: 35px;
  }
}
@-webkit-keyframes arrow {
  0% {
    top: 35px;
  }
  75% {
    top: 0px;
  }
  100% {
    top: 35px;
  }
}
@keyframes circle {
  0% {
    height: 220px;
  }
  10% {
    height: 220px;
  }
  50% {
    height: 230px;
  }
  75% {
    height: 250px;
  }
  90% {
    height: 230px;
  }
  100% {
    height: 220px;
  }
}
@-webkit-keyframes circle {
  0% {
    height: 220px;
  }
  10% {
    height: 220px;
  }
  50% {
    height: 230px;
  }
  75% {
    height: 250px;
  }
  90% {
    height: 230px;
  }
  100% {
    height: 220px;
  }
}
